import Vue from 'vue'
import Router from 'vue-router'

const HomePage = () => import(/* webpackChunkName: "HomePage" */ '@/pages/HomePage.vue');
const AboutPage = () => import(/* webpackChunkName: "AboutPage" */ '@/pages/AboutPage.vue');
const ServicesPage = () => import(/* webpackChunkName: "AboutPage" */ '@/pages/ServicesPage.vue');
const ContactsPage = () => import(/* webpackChunkName: "ContactsPage" */ '@/pages/ContactsPage.vue');
const PartnersPage = () => import(/* webpackChunkName: "PartnersPage" */ '@/pages/PartnersPage.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "PartnersPage" */ '@/pages/PrivacyPolicy.vue');

Vue.use(Router);

const routes = [
    {
        path: '/',
        component: {
            template: '<router-view></router-view>',
        },

        children: [
            {
                path: '',
                name: 'HomePage',
                component: HomePage,
            },
            {
                path: 'about-us',
                name: 'AboutPage',
                component: AboutPage,
            },
            {
                path: 'services',
                name: 'ServicesPage',
                component: ServicesPage,
            },
            {
                path: 'partners',
                name: 'PartnersPage',
                component: PartnersPage,
            },
            {
                 path: 'contacts',
                 name: 'ContactsPage',
                 component: ContactsPage,
             },
            {
                path: 'privacy-policy',
                name: 'PrivacyPolicy',
                component: PrivacyPolicy,
            },
        ],
    },
];

const router = new Router({
        mode: 'history',
        routes,
        scrollBehavior(to) {
            if (to.hash) {
                return { selector: to.hash };
            }
            return {
                x: 0,
                y: 0,
                behavior: 'smooth'
            };
        },
    });

export default router;

