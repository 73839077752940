<template>
  <footer class="Footer">
    <div class="Container">
      <div class="Footer-Content">
        <div class="Footer-Routs">
          <router-link to="/" class="Footer-Logo">
            <img src="../assets/images/logo-footer.svg" alt="logo" class="Footer-LogoLink">
          </router-link>
          <div class="Footer-Privacy">
            <div class="Footer-Copy">Copyright © 2020.</div>
            <router-link to="/privacy-policy" class="Footer-PrivacyLink">
              Privacy Policy
            </router-link>
          </div>
        </div>
        <div class="Footer-Contacts">
          <a href="tel:+35725377077" class="Footer-Link Phone">+35725377077</a>
          <a href="mailto:info@digitaliusltd.com" class="Footer-Link Mail">info@digitaliusltd.com</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MainFooter"
}
</script>

<style scoped lang="scss">
.Footer {
  padding: 27px 0;
  background-color: var(--color-bg-ghost);
  box-shadow: 0px -2px 10px rgba(29, 29, 37, 0.15);

  &-Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (min-width: $screen-s) {
      flex-direction: row;
      justify-content: space-between;
      text-align: start;
    }
  }

  &-Routs {
    margin-bottom: 30px;
    @media (min-width: $screen-s) {
      margin-bottom: 0;
    }
  }

  &-Logo {
    margin-bottom: 5px;
  }

  &-Privacy {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.66;
    color: var(--color-text-ghost-light);
  }

  &-Copy {
    margin-right: 5px;
  }

  &-Contacts {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
  }

  &-Link {
    margin-bottom: 13px;
    padding-left: 25px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.21;
    color: var(--color-text-main2);
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .Phone {
    &:before {
      width: 14px;
      height: 14px;
      background-image: url(~@/assets/images/phone.svg);
    }
  }

  .Mail {
    &:before {
      width: 16px;
      height: 12px;
      background-image: url(~@/assets/images/email.svg);
    }
  }
}
</style>